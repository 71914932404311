import React from "react";
import { ContactModal } from "../common";
import { Button, Carousel, Col, Container, Image, Row } from "react-bootstrap";
import LinkContainer from "react-router-bootstrap/LinkContainer";

import "../css/index.scss"

class GalleryImage extends React.Component {
  render() {
    return <Image className="w-100" src={this.props.uri} fluid rounded thumbnail></Image>;
  }
}

function GalleryCaption() {
  return (
    <div>
      <Carousel.Caption>
        <h2 className="fluencyTitle">Fluency in English</h2>
        <h6 className="fluencySubtitle">La mejor enseñanza de Ingles en Pacheco.</h6>
      </Carousel.Caption>
    </div>
  );
}

export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loggedIn: false,
      showContactModal: false,
    };

    this.validateSession = this.validateSession.bind(this);
    this.showModalHandler = this.showModalHandler.bind(this);
    this.closeModalHandler = this.closeModalHandler.bind(this);
  }

  componentDidMount() {
    this.validateSession();
  }

  validateSession() {
    let validationRequest = new XMLHttpRequest();
    validationRequest.open("GET", "/validation");
    validationRequest.send();

    let t = this;

    validationRequest.onreadystatechange = function () {
      if (this.readyState === 4) {
        t.setState({
          loggedIn: this.status === 202,
        });
      }
    };
  }

  showModalHandler() {
    this.setState({
      showContactModal: true,
    });
  }

  closeModalHandler() {
    this.setState({
      showContactModal: false,
    });
  }

  render() {
    var userButton;

    const handleClose = () => this.closeModalHandler();
    const handleShow = () => this.showModalHandler();

    if (this.state.loggedIn) {
      userButton = (
        <LinkContainer to="/profile">
          <Button variant="outline-light">Ir a Tu Perfil</Button>
        </LinkContainer>
      );
    } else {
      userButton = (
        <LinkContainer to="/login">
          <Button variant="outline-dark">Iniciar Sesión</Button>
        </LinkContainer>
      );
    }

    return (
      <Container className="my-2 h-100">
        <Carousel className="mb-3" fade>
          <Carousel.Item>
            <GalleryImage uri="../../img/01.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/02.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/03.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/04.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/05.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/06.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/07.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/08.jpg" />
            <GalleryCaption />
          </Carousel.Item>

          <Carousel.Item>
            <GalleryImage uri="../../img/09.jpg" />
            <GalleryCaption />
          </Carousel.Item>
        </Carousel>

        <hr className="my-1" />

        <Row className="ms-2 my-5">
          <Col className="d-flex align-items-center justify-content-center">
            <h1 className="display-5">Cursos para todas las edades.</h1>
          </Col>

          <Col className="d-flex align-items-center justify-content-center">
            <LinkContainer to="/classes">
              <Button variant="outline-dark">Propuesta educativa</Button>
            </LinkContainer>
          </Col>
        </Row>

        <hr className="my-1" />

        <Row className="me-2 my-5">
          <Col className="d-flex align-items-center justify-content-center">
            <Button variant="outline-dark" onClick={handleShow}>
              Ponete en Contacto
            </Button>
          </Col>

          <Col className="d-flex align-items-center justify-content-center">
            <h1 className="display-5">Estamos para responder tus dudas.</h1>
          </Col>
        </Row>

        <hr className="my-1" />

        <Row className="ms-2 my-5">
          <Col className="d-flex align-items-center justify-content-center">
            <h1 className="display-5">¿Ya sos Alumno del Instituto?</h1>
          </Col>

          <Col className="d-flex align-items-center justify-content-center">
            {userButton}
          </Col>
        </Row>

        <hr className="my-1" />

        <ContactModal show={this.state.showContactModal} handleClose={handleClose} />
      </Container>
    );
  }
}
