import React from "react";
import { UserDropdown } from "./common";
import { Outlet } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Container, Navbar } from "react-bootstrap";

function App() {
  return (
    <div className="App h-100">
      <Navbar bg="light" expand="lg">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>Fluency in English</Navbar.Brand>
          </LinkContainer>

          <UserDropdown />
        </Container>
      </Navbar>

      <Outlet />
    </div>
  );
}

export default App;
