import React, { useState } from "react";
import { Button, Card, Container, Row } from "react-bootstrap";
import { ContactModal } from "../common";

function ClassCard(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="mb-2 px-4">
      <Card>
        <Card.Body>
          <Card.Title>{props.title}</Card.Title>

          <Card.Subtitle className="mb-2 text-muted">
            Edad: {props.ages}
          </Card.Subtitle>
          <Card.Subtitle className="mb-2 text-muted">
            Dias: {props.days}
          </Card.Subtitle>

          <Card.Text>{props.description}</Card.Text>

          <hr></hr>

          <Button variant="outline-primary" onClick={handleShow}>
            Mas Información
          </Button>
        </Card.Body>
      </Card>

      <ContactModal show={show} handleClose={handleClose} />
    </div>
  );
}

export default function Classes() {
  return (
    <Container className="h-100 my-4 align-middle">
      <h5 className="display-5">Nuestra Propuesta Educativa</h5>
      <p className="mb-2 text-muted">
        Acompañamos a nuestros alumnos a traves de todo su viaje en el
        aprendizaje del Ingles - desde sus primeros pasos en el idioma, hasta que
        rinden exámenes de calibre internacional, como el First Certificate in
        English (FCE). Todos nuestros cursos son por la Tarde.
      </p>

      <hr />

      <Row>
        <h5
          className="display-6 mb-3"
          style={{ fontSize: 2 + "rem", fontWeight: "400" }}
        >
          Niveles Iniciales
        </h5>

        <ClassCard
          title="Kinder"
          ages="3 a 4 años"
          days="No establecido"
          description="A traves de juegos y actividades especialmente diseñadas para cada grupo, vamos introduciendo a los mas chiquitos en el mundo magico de aprender otro idioma."
        />

        <ClassCard
          title="Starter Fun - Starter 3"
          ages="5 a 6 años (Starter Fun), 7 a 8 años (Starter 2), 8 a 9 años (Starter 3)"
          days="Miercoles (Starter 2 y 3), Viernes (Starter Fun)"
          description="Cursos apuntados a niños cursando primer grado en adelante. Los grupos se forman teniendo en cuenta las edades, conocimientos previos, y manejo de la lectoescritura en el idioma castellano."
        />

        <ClassCard
          title="Prep Course 1 - Prep Course 3"
          ages="10 a 14 años"
          days="Lunes (Prep 3), Viernes (Prep 1)"
          description="Curso de nivelación para pre-adolescentes y adolescentes con minimo o ningun conocimiento del idioma. Se busca que alcancen un nivel intermedio en 2 o 3 años para poder integrarlos a nuestros otros cursos."
        />
      </Row>

      <hr />

      <Row>
        <h5
          className="display-6 mb-3"
          style={{ fontSize: 2 + "rem", fontWeight: "400" }}
        >
          Niveles Intermedios
        </h5>

        <ClassCard
          title="Kids 4 - Kids 5"
          ages="Variable segun evaluacion"
          days="Viernes (Kids 4)"
          description="Dirigido a niños con conocimientos del idioma. Se les hace una entrevista previa para evaluar informalmente a través de su material trabajado cual es el mejor grupo para el alumno."
        />

        <ClassCard
          title="Teens 6 - Teens 9"
          ages="Variable segun evaluacion"
          days="Lunes (Teens 6), Martes (Teens 8), Jueves (Teens 7 y 8)"
          description="Dirigido a pre-adolescentes y adolescentes con un nivel, intermedio, o avanzado del idioma. De ser necesario, se los evalúa previamente sin costo adicional."
        />
      </Row>

      <hr />

      <Row>
        <h5
          className="display-6 mb-3"
          style={{ fontSize: 2 + "rem", fontWeight: "400" }}
        >
          Niveles Avanzados
        </h5>

        <ClassCard
          title="FCE - CAE - Proficiency"
          ages="Variable segun evaluacion"
          days="No establecido"
          description="Preparación para estos exámenes de University of Cambridge. Se evalúa al alumno antes de ingresar, a menos que ya haya rendido algún examen y presente su certificado."
        />
      </Row>

      <hr />
    </Container>
  );
}
