import React from "react";
import { createRoot } from "react-dom/client";

import { Spinner } from "react-bootstrap";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import App from "./App";

import Classes from "./routes/classes";
import Home from "./routes/home";
import Login from "./routes/login";
import Register from "./routes/register";
import { suspenseFallback } from "./common";

import "./css/extra.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Profile = React.lazy(() => import("./routes/profile"));
const Notifications = React.lazy(() => import("./routes/notifications"));

const container = document.getElementById("app");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />

          <Route path="/classes" element={<Classes />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />

          <Route
            path="/profile"
            element={
              <React.Suspense fallback={suspenseFallback}>
                <Profile />
              </React.Suspense>
            }
          />
          <Route
            path="/notifications"
            element={
              <React.Suspense
                fallback={<Spinner animation="border" role="status"></Spinner>}
              >
                {" "}
                <Notifications />{" "}
              </React.Suspense>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);
