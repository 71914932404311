import React from "react";
import { useFormik } from "formik";
import { Classes, ErrorToast } from "../common";
import { Button, ButtonGroup, Col, Container, Form, Row, Spinner } from "react-bootstrap";

class AdultInfo extends React.Component {
  constructor(props) {
    super(props);

    this.addEntry = this.addEntry.bind(this);
    this.removeEntry = this.removeEntry.bind(this);

    this.state = {
      elementsCount: 2,

      blurCallback: props.blurCallback,
      changeCallback: props.changeCallback,
    };
  }

  render() {
    var infoFields = [];

    for (let i = 0; i < this.state.elementsCount; i++) {
      infoFields.push(
        <AdultInfoGroup
          blurCallback={this.state.blurCallback}
          changeCallback={this.state.changeCallback}
          key={i.toString()}
          number={i}
        ></AdultInfoGroup>
      );
    }

    return (
      <div>
        <div id="adultsGroup">{infoFields}</div>

        <ButtonGroup size="sm">
          <Button size="sm" onClick={this.addEntry}>
            Agregar
          </Button>
          <Button
            size="sm"
            onClick={this.removeEntry}
            disabled={this.state.elementsCount <= 2}
          >
            Remover
          </Button>
        </ButtonGroup>
      </div>
    );
  }

  addEntry() {
    this.setState({
      elementsCount: this.state.elementsCount + 1,
    });
  }

  removeEntry() {
    if (this.state.elementsCount > 2) {
      this.setState({
        elementsCount: this.state.elementsCount - 1,
      });
    }
  }
}

function AdultInfoGroup(props) {
  return (
    <Row>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Nombre y Apellido:</Form.Label>
          <Form.Control
            id={`adultName${props.number}`}
            name={`adultsInfo[${props.number}].name`}
            type="text"
            onBlur={props.blurCallback}
            onChange={props.changeCallback}
            required
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Teléfono de Contacto:</Form.Label>
          <Form.Control
            id={`adultPhone${props.number}`}
            name={`adultsInfo[${props.number}].phone`}
            type="text"
            onBlur={props.blurCallback}
            onChange={props.changeCallback}
            required
          />
        </Form.Group>
      </Col>

      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Relación:</Form.Label>
          <Form.Control
            id={`adultRelationship${props.number}`}
            name={`adultsInfo[${props.number}].relationship`}
            type="text"
            placeholder="Padre/Madre/Etc"
            onBlur={props.blurCallback}
            onChange={props.changeCallback}
            required
          />
        </Form.Group>
      </Col>
    </Row>
  );
}

const RegisterForm = (props) => {
  const formik = useFormik({
    initialValues: {
      name: "",

      dni: "",
      dniConfirm: "",

      email: "",
      emailConfirm: "",

      pwd: "",
      pwdConfirm: "",

      studentClass: "Kinder",
      studentAddress: "",
      studentBirthday: new Date(),

      adultsInfo: [],

      otherNotes: "",
    },
    validate: formValidation,
    onSubmit: (values) => {
      let adultsData = values.adultsInfo.map((adult) => [
        adult.name,
        adult.phone,
        adult.relationship,
      ]);

      var birthdayObject = {
        $date: new Date(values.studentBirthday).toISOString(),
      };

      let registerData = {
        name: values.name,
        dni: parseInt(values.dni),
        email: values.email,
        password: values.pwd,
        class: values.studentClass,
        address: values.studentAddress,
        birthday: birthdayObject,
        adults: adultsData,
        notes: values.otherNotes,
      };

      let dataSer = JSON.stringify(registerData);

      let registerRequest = new XMLHttpRequest();
      registerRequest.open("POST", "/register");
      registerRequest.send(dataSer);

      props.updateCallback(true);

      registerRequest.onreadystatechange = function () {
        if (this.readyState === 4) {
          props.updateCallback(false);

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });

          switch (this.status) {
            // All good.
            case 200:
              window.location = "/login";
              break;

            // Account exists.
            case 409:
              props.toastCallback(true, "Ya existe una cuenta con el DNI ingresado.");
              break;

            // Backend issue.
            case 500:
              props.toastCallback(
                true,
                "Ocurrió un error al procesar tu solicitud, volvé a intentar mas tarde."
              );
              break;

            default:
              break;
          }
        }
      };
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Nombre y Apellido:</Form.Label>
            <Form.Control
              id="name"
              name="name"
              type="name"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.name && formik.errors.name}
              value={formik.values.name}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.name}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>DNI:</Form.Label>
            <Form.Control
              id="dni"
              name="dni"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.dni && formik.errors.dni}
              value={formik.values.dni}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.dni}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Confirmar DNI:</Form.Label>
            <Form.Control
              id="dniConfirm"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.dniConfirm && formik.errors.dniConfirm}
              value={formik.values.dniConfirm}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.dniConfirm}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Email:</Form.Label>
            <Form.Control
              id="email"
              name="email"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.email && formik.errors.email}
              value={formik.values.email}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Confirmar Email:</Form.Label>
            <Form.Control
              id="emailConfirm"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.emailConfirm && formik.errors.emailConfirm}
              value={formik.values.emailConfirm}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.emailConfirm}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Contraseña:</Form.Label>
            <Form.Control
              id="pwd"
              name="pwd"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.pwd && formik.errors.pwd}
              value={formik.values.pwd}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.pwd}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Confirmar Contraseña:</Form.Label>
            <Form.Control
              id="pwdConfirm"
              type="password"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.pwdConfirm && formik.errors.pwdConfirm}
              value={formik.values.pwdConfirm}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.pwdConfirm}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Curso del Alumno:</Form.Label>
            <Form.Select
              id="studentClass"
              name="studentClass"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.studentClass}
              required
            >
              {Classes}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Domicilio:</Form.Label>
            <Form.Control
              id="studentAddress"
              name="studentAddress"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.studentAddress && formik.errors.studentAddress}
              value={formik.values.studentAddress}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.studentAddress}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col>
          <Form.Group className="position-relative mb-3">
            <Form.Label>Fecha de Nacimiento:</Form.Label>
            <Form.Control
              id="studentBirthday"
              name="studentBirthday"
              type="date"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              isInvalid={formik.touched.studentBirthday && formik.errors.studentBirthday}
              value={formik.values.studentBirthday}
              required
            />

            <Form.Control.Feedback type="invalid" tooltip>
              {formik.errors.studentBirthday}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <hr />

      <Row>
        <Form.Group className="position-relative mb-3">
          <Form.Label>Teléfonos de Contacto:</Form.Label>
          <AdultInfo
            blurCallback={formik.handleBlur}
            changeCallback={formik.handleChange}
          />
        </Form.Group>
      </Row>

      <hr />

      <Row>
        <Form.Group className="mb-3">
          <Form.Label>Otras Notas:</Form.Label>
          <Form.Control id="otherNotes" name="otherNotes" as="textarea"></Form.Control>
          <Form.Text muted>
            Cualquier otra cosa que consideres relevante sobre el alumno.
          </Form.Text>
        </Form.Group>
      </Row>

      <Button variant="outline-success" type="submit" disabled={props.submitted}>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          hidden={!props.submitted}
        />
        Registrarse
      </Button>
    </Form>
  );
};

const formValidation = (values) => {
  const errors = {};
  const requiredField = "Este campo es obligatorio.";
  const mismatchedValues = "Los valores ingresados no coinciden.";

  if (!values.name) {
    errors.name = requiredField;
  }

  if (!values.dni) {
    errors.dni = requiredField;
  } else if (!/^\d+$/.test(values.dni)) {
    if (values.dni.includes(".")) {
      errors.dni = "Por favor, ingresa el DNI sin puntos.";
    } else {
      errors.dni = "El valor ingresado no es un numero valido.";
    }
  }

  if (!values.dniConfirm) {
    errors.dniConfirm = requiredField;
  } else if (!/^\d+$/.test(values.dniConfirm)) {
    if (values.dniConfirm.includes(".")) {
      errors.dniConfirm = "Por favor, ingresa el DNI sin puntos.";
    } else {
      errors.dniConfirm = "El valor ingresado no es un numero valido.";
    }
  }

  if (values.dni && values.dniConfirm) {
    if (values.dni !== values.dniConfirm) {
      errors.dni = mismatchedValues;
      errors.dniConfirm = mismatchedValues;
    }
  }

  if (!values.email) {
    errors.email = requiredField;
  }

  if (!values.emailConfirm) {
    errors.emailConfirm = requiredField;
  }

  if (values.email && values.emailConfirm) {
    if (values.email !== values.emailConfirm) {
      errors.email = mismatchedValues;
      errors.emailConfirm = mismatchedValues;
    }
  }

  if (!values.pwd) {
    errors.pwd = requiredField;
  }

  if (!values.pwdConfirm) {
    errors.pwdConfirm = requiredField;
  }

  if (values.pwd && values.pwdConfirm) {
    if (values.pwd !== values.pwdConfirm) {
      errors.pwd = mismatchedValues;
      errors.pwdConfirm = mismatchedValues;
    }
  }

  if (!values.studentAddress) {
    errors.studentAddress = requiredField;
  }

  if (!values.studentBirthday) {
    errors.studentBirthday = requiredField;
  } else {
    let today = new Date();
    let todayYear = today.getFullYear();

    if (new Date(values.studentBirthday).getFullYear() >= todayYear) {
      errors.studentBirthday = "Ingresa una fecha de nacimiento valida.";
    }
  }

  return errors;
};

export default class Register extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,

      showErrorToast: false,
      errorToastContent: "",
    };

    this.closeToast = this.closeToast.bind(this);
    this.updateErrorToast = this.updateErrorToast.bind(this);
    this.updateRegisterState = this.updateRegisterState.bind(this);
  }

  componentDidMount() {
    let validationRequest = new XMLHttpRequest();
    validationRequest.open("GET", "/validation");
    validationRequest.send();

    validationRequest.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 202) {
          window.location = "/profile";
        }
      }
    };
  }

  closeToast() {
    this.setState({
      showErrorToast: false,
    });
  }

  updateErrorToast(show, content) {
    this.setState({
      showErrorToast: show,
      errorToastContent: content,
    });
  }

  updateRegisterState(newState) {
    this.setState({
      submitted: newState,
    });
  }

  render() {
    return (
      <Container className="my-4 align-middle">
        <ErrorToast
          showToast={this.state.showErrorToast}
          toastHeader="Error al crear la cuenta"
          toastContent={this.state.errorToastContent}
          closeCallback={this.closeToast}
        />

        <Row className="justify-content-center align-items-center">
          <Col className="col-10 col-md-8 col-lg-6">
            <h2 className="display-6">Registro</h2>

            <hr />

            <RegisterForm
              submitted={this.state.submitted}
              toastCallback={this.updateErrorToast}
              updateCallback={this.updateRegisterState}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
