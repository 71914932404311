import React from "react";
import { Button, ButtonGroup, Col, Container, Form, Row, Spinner } from "react-bootstrap";

import { useFormik } from "formik";
import { ErrorToast } from "../common";

class LoginInfo {
  constructor(dni, pwd, remember) {
    this.dni = dni;
    this.password = pwd;
    this.remember_me = remember;
  }
}

const LoginForm = (props) => {
  const formik = useFormik({
    initialValues: {
      dni: "",
      password: "",
      remember_me: false,
    },
    validate: formValidation,
    onSubmit: (values) => {
      let loginForm = new LoginInfo(
        parseInt(values.dni),
        values.password,
        values.remember_me
      );

      let loginRequest = new XMLHttpRequest();
      loginRequest.open("POST", "/login");
      loginRequest.send(JSON.stringify(loginForm));

      props.updateCallback(true);

      loginRequest.onreadystatechange = function () {
        if (this.readyState === 4) {
          props.updateCallback(false);

          switch (this.status) {
            // All good.
            case 200:
              window.location = "/profile";
              break;

            // Bad credentials.
            case 401:
              props.toastCallback(
                true,
                "Los datos ingresados no coinciden con ninguna cuenta, volvé a intentarlo."
              );
              break;

            // Backend issue.
            case 500:
              props.toastCallback(
                true,
                "Ocurrió un error al procesar tu solicitud, volvé a intentar mas tarde."
              );
              break;

            default:
              break;
          }
        }
      };
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      <Form.Group className="position-relative mb-3">
        <Form.Label>DNI</Form.Label>
        <Form.Control
          id="dni"
          name="dni"
          type="text"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isInvalid={formik.touched.dni && formik.errors.dni}
          value={formik.values.dni}
          required
        />

        <Form.Control.Feedback type="invalid" tooltip>
          {formik.errors.dni}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="position-relative mb-3">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control
          id="password"
          name="password"
          type="password"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          isInvalid={formik.touched.password && formik.errors.password}
          value={formik.values.password}
          required
        />

        <Form.Control.Feedback type="invalid" tooltip>
          {formik.errors.password}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Check
          id="remember_me"
          name="remember_me"
          type="checkbox"
          label="Mantener la sesión abierta"
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          checked={formik.values.remember_me}
        />
      </Form.Group>

      <hr />

      <ButtonGroup>
        <Button variant="outline-success" type="submit" disabled={props.submitted}>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            hidden={!props.submitted}
          />
          Iniciar Sesión
        </Button>
      </ButtonGroup>
    </Form>
  );
};

const formValidation = (values) => {
  const errors = {};

  if (!values.dni) {
    errors.dni = "Este campo es obligatorio.";
  } else if (!/^\d+$/.test(values.dni)) {
    if (values.dni.includes(".")) {
      errors.dni = "Por favor, ingresa el DNI sin puntos.";
    } else {
      errors.dni = "El valor ingresado no es un numero valido.";
    }
  }

  if (!values.password) {
    errors.password = "Este campo es obligatorio.";
  } else if (values.password.length < 8) {
    errors.password = "La contraseña ingresada esta por debajo del mínimo de caracteres.";
  }

  return errors;
};

export default class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,

      showErrorToast: false,
      errorToastHeader: "",
      errorToastContent: "",
    };

    this.closeToast = this.closeToast.bind(this);
    this.updateLoginState = this.updateLoginState.bind(this);
    this.updateErrorToast = this.updateErrorToast.bind(this);
  }

  componentDidMount() {
    let validationRequest = new XMLHttpRequest();
    validationRequest.open("GET", "/validation");
    validationRequest.send();

    validationRequest.onreadystatechange = function () {
      if (this.readyState === 4) {
        if (this.status === 202) {
          window.location = "/profile";
        }
      }
    };

    if (document.cookie.includes("register_ok")) {
      this.updateErrorToast(
        true,
        "¡Ya podes iniciar sesión!",
        "Nueva cuenta creada con éxito."
      );

      document.cookie = "register_ok= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  closeToast() {
    this.setState({
      showErrorToast: false,
    });
  }

  updateLoginState(newState) {
    this.setState({
      submitted: newState,
    });
  }

  updateErrorToast(show, content, header = "Error al iniciar sesión") {
    this.setState({
      showErrorToast: show,
      errorToastHeader: header,
      errorToastContent: content,
    });
  }

  render() {
    return (
      <Container className="h-100 align-middle">
        <ErrorToast
          showToast={this.state.showErrorToast}
          toastHeader={this.state.errorToastHeader}
          toastContent={this.state.errorToastContent}
          closeCallback={this.closeToast}
        />

        <Row className="h-100 justify-content-center align-items-center">
          <Col className="col-10 col-md-8 col-lg-6">
            <h2 className="display-6">Iniciar Sesión</h2>

            <hr />

            <LoginForm
              submitted={this.state.submitted}
              toastCallback={this.updateErrorToast}
              updateCallback={this.updateLoginState}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}
